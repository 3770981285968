const info = {
  web: {
    title: 'Smithy Print',
  },
  meta: {
    title: 'Smithy Print',
    description: 'Smithy Print description',
  },
  address: {
    street: 'Vladimíra Clementisa',
    number: '6644/31',
    city: 'Trnava',
    zip: '917 01',
    country: 'Slovensko',
  },
  company: {
    name: 'Smithy Industries s.r.o.',
    ico: '53307968',
    dic: '2121333874',
  },
  contact: {
    email: 'smithyindustries420@gmail.com',
  },
};

export default info;
