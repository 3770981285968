import React, { useState } from 'react';

const Accordions = ({ accordions }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  return (
    <div>
      {accordions.map((accordion) => (
        <div key={accordion.id}>
          <div
            onClick={() => setActiveAccordion(accordion.id)}
            className="text-primary text-xl underline cursor-pointer mb-1"
          >
            <span>{accordion.title}</span>
          </div>
          <ul
            className={`list-disc pl-6 overflow-hidden transition-all duration-300 ${
              activeAccordion === accordion.id ? 'max-h-screen' : 'max-h-0'
            }`}
          >
            {accordion.content.split('$').map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Accordions;
