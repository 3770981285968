import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MyContext } from '../../context';

const Footer = () => {
  const {
    project: { info },
  } = useContext(MyContext);
  const { t } = useTranslation();
  return (
    <section className="py-8">
      <div className="md:container md:mx-auto md:px-8 px-3 space-y-3">
        <h2 className="text-3xl">{t('vop.title')}</h2>
        <section>
          <h3 className="text-2xl mb-2">{t('vop.company')}</h3>
          <ul>
            <li>
              <span>{info.company.name}</span>
            </li>
            <li>
              <span>
                {info.address.street} {info.address.number}
              </span>
            </li>
            <li>
              <span>
                {info.address.city} {info.address.zip}
              </span>
            </li>
            <li>
              <span>{info.address.country}</span>
            </li>
            <li>
              <span>{t('footer.company.ico')}</span>: <span>{info.company.ico}</span>
            </li>
            <li>
              <span>{t('footer.company.dic')}</span>: <span>{info.company.dic}</span>
            </li>
            <li>
              <span>{t('footer.company.icDph')}</span>: <span>{info.company.icDph}</span>
            </li>
            <li>
              <p className="text-xl mt-3">{t('footer.contact.title')}</p>
            </li>
            <li>
              <span>{t('footer.contact.email')}</span>: <span>{info.contact.email}</span>
            </li>
            <li>
              <span>{t('footer.contact.phone')}</span>: <span>{info.contact.phone}</span>
            </li>
          </ul>
        </section>

        <section>
          <h3 className="text-2xl mb-2">{t('vop.intro.title')}</h3>
          <p>{t('vop.intro.text')}</p>
        </section>

        <section>
          <h3 className="text-2xl mb-2">{t('vop.collectingData.title')}</h3>
          <p>{t('vop.collectingData.text')}</p>
        </section>
      </div>
    </section>
  );
};

export default Footer;
