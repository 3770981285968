import JTColors from './journey-trade/colors';
import JTFooter from './journey-trade/Footer';
import JTIndexPage from './journey-trade/indexPage';
import JTVopPage from './journey-trade/VopPage';
import JTInfo from './journey-trade/info';
import JTPages from './journey-trade/pages';
import SmithyColors from './smithy/colors';
import SmithyFooter from './smithy/Footer';
import SmithyIndexPage from './smithy/indexPage';
import SmithyVopPage from './smithy/VopPage';
import SmithyInfo from './smithy/info';
import SmithyPages from './smithy/pages';
import AtanerColors from './ataner/colors';
import AtanerFooter from './ataner/Footer';
import AtanerIndexPage from './ataner/indexPage';
import AtanerVopPage from './ataner/VopPage';
import AtanerInfo from './ataner/info';
import AtanerPages from './ataner/pages';

const projects = {
  journeyTrade: {
    pages: JTPages,
    colors: JTColors,
    indexPage: JTIndexPage,
    footerComp: JTFooter,
    vopPage: JTVopPage,
    info: JTInfo,
    isMultiLang: true,
  },
  smithy: {
    pages: SmithyPages,
    colors: SmithyColors,
    indexPage: SmithyIndexPage,
    footerComp: SmithyFooter,
    vopPage: SmithyVopPage,
    info: SmithyInfo,
    isMultiLang: false,
  },
  ataner: {
    pages: AtanerPages,
    colors: AtanerColors,
    indexPage: AtanerIndexPage,
    footerComp: AtanerFooter,
    vopPage: AtanerVopPage,
    info: AtanerInfo,
    isMultiLang: false,
  },
};

export default projects;
