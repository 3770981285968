const info = {
  web: {
    title: 'Ataner s.r.o. ',
  },
  meta: {
    title: 'ATANER s.r.o.',
    description:
      'Prinášame komplexné služby pre podnikateľov, ako sú živnostníci, malé až stredne veľké firmy. Poskytujeme spracovanie účtovníctva, vrátane miezd a personalistiky, daňového, finančného a právneho poradenstva.',
    host: 'https://ataner.net',
  },
  address: {
    street: 'Hviezdoslavova',
    number: '224/3',
    city: 'Trnava',
    zip: '917 01',
    country: 'Slovensko',
  },
  company: {
    name: 'ATANER s.r.o.',
    ico: '45955751',
    dic: '2023152736',
    icDph: 'SK2023152736',
  },
  contact: {
    email: 'ataner@ataner.net',
    phone: '+421907441254',
  },
};

export default info;
