const info = {
  web: {
    title: 'JOURNEY TRADE',
  },
  meta: {
    title: 'Journey Trade',
    description:
      'Spoločnosť JourneyTrade ponúka veľkoobchodné ceny na prémiové produkty na čistenie domácnosti a starostlivosť o osobnú hygienu. Kontaktujte nás pre viac informácií a objednávky.',
    host: 'https://journeytrade.eu',
  },
  address: {
    street: 'Ulica Ľudmily Podjavorinskej',
    number: '2543/15',
    city: 'Trnava',
    zip: '917 01',
    country: 'Slovensko',
  },
  company: {
    name: 'JOURNEY TRADE s.r.o.',
    ico: '46879455',
    dic: '2023637396',
    icDph: 'SK2023637396',
  },
  contact: {
    email: 'info@journeytrade.eu',
    phone: '+421907441254',
    phoneIt: '+393275757441',
  },
};

export default info;
