import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MdContentCopy } from 'react-icons/md';
import SplitSection from '../../components/SplitSection';
import { MyContext } from '../../context';
import DroneVideo from './assets/video.mp4';

const useProjectAssets = () => {
  const data = useStaticQuery(
    graphql`
      query SmithyProjectAssets {
        allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    `
  );
  return data.allFile.nodes;
};

const SplitSectionJourney = ({ id, title, description, reverse = false, img }) => (
  <SplitSection
    id={id}
    primarySlot={
      <div className={reverse ? '' : 'lg:pr-16 '}>
        <h3 className="text-3xl font-semibold leading-tight text-primary md:text-left text-center">
          {title}
        </h3>
        <p className="mt-8 text-xl font-light leading-relaxed text-justify">{description}</p>
      </div>
    }
    secondarySlot={img}
    reverseOrder={reverse}
  />
);

const IndexPage = () => {
  const {
    project: { pages, info },
  } = useContext(MyContext);
  const { t } = useTranslation('smithy');
  const assets = useProjectAssets();
  const hero = getImage(assets.find((asset) => asset.name === 'hero'));
  const printImage = getImage(assets.find((asset) => asset.name === 'print'));
  const { email } = info.contact;
  return (
    <div id="home">
      <div>
        <Toaster />
      </div>
      <section className="">
        <GatsbyImage image={hero} alt="TODO META" objectFit="cover" className="md:h-128 h-64" />
      </section>
      <SplitSectionJourney
        id={pages[0]}
        title={t(`sections.${pages[0]}.title`)}
        description={t(`sections.${pages[0]}.description`)}
        img={
          <GatsbyImage
            image={printImage}
            alt="TODO META"
            objectFit="cover"
            className="sm:h-128 h-64 drop-shadow"
          />
        }
      />
      <SplitSectionJourney
        id={pages[1]}
        title={t(`sections.${pages[1]}.title`)}
        description={t(`sections.${pages[1]}.description`)}
        img={
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls>
            <source
              src={DroneVideo}
              type="video/mp4"
              alt="TODO META"
              className="sm:h-128 h-64 drop-shadow"
            />
          </video>
        }
        reverse
      />
      {/* <SplitSectionJourney
        id={pages[2]}
        title={t(`sections.${pages[2]}.title`)}
        description={t(`sections.${pages[2]}.description`)}
        img={
          <GatsbyImage
            image={cleaningImage}
            alt="TODO META"
            objectFit="cover"
            className="sm:h-128 h-64 drop-shadow"
          />
        }
      /> */}
      {/* <section id="testimonials" className="py-20 lg:py-40">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">
            What customers are saying
          </LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {customerData.map((customer) => (
              <div key={customer.customerName} className="flex-1 px-3">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section> */}
      <section
        className="container mx-auto mt-16 sm:py-24 py-12 bg-secondary rounded-lg text-center"
        id={pages[3]}
      >
        <h3 className="text-5xl font-semibold">{t(`sections.${pages[2]}.title`)}</h3>
        <p className="mt-8 text-xl font-light mb-4">{t(`sections.${pages[2]}.description`)}</p>
        <div className="flex justify-around items-center sm:flex-row flex-col">
          <div className="flex text-primary text-xl sm:mb-0 mb-3 items-center">
            <a href={`mailto:${email}`} className="mr-2" title="TODO META">
              {email}
            </a>
            <MdContentCopy
              className="hover:cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(email);
                toast(t(`sections.${pages[3]}.copy.email`), {
                  position: 'bottom-right',
                });
              }}
            />
          </div>
          {/* <div className="flex text-primary text-xl sm:mb-0 mb-3 items-center">
            <a href={`tel:${phone}`} className="mr-2" title="TODO META">
              {phone}
            </a>
            <MdContentCopy
              className="hover:cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(phone);
                toast(t(`sections.${pages[3]}.copy.phone`), {
                  position: 'bottom-right',
                });
              }}
            />
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default IndexPage;
