// const pages = [
//   'about',
//   'prototyping',
//   'video',
//   'customising',
//   'replacements',
//   'art',
//   'showcase',
//   'contact',
// ];
const pages = ['prototyping', 'video', 'contact'];
export default pages;
