import React, { useContext, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal/lib/components/Modal';
import { MyContext } from '../../context';

const Column = ({ title, children }) => (
  <div className="">
    <h2 className="text-lg font-semibold text-primary">{title}</h2>
    <ul className="sm:mt-4 mt-2 leading-loose">{children}</ul>
  </div>
);

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 20,
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '960px',
    margin: '32px auto',
    padding: 0,
    border: 0,
  },
};

const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    project: { info, pages, vopPage: VopPage },
  } = useContext(MyContext);
  const { t } = useTranslation();
  return (
    <footer className="container mx-auto md:py-16 py-8 px-5 text-gray-800">
      <div className="flex justify-around sm:flex-row flex-col sm:space-y-0 space-y-3">
        <Column title={t('footer.address.title')}>
          <>
            <li>
              <span>{info.company.name}</span>
            </li>
            <li>
              <span>
                {info.address.street} {info.address.number}
              </span>
            </li>
            <li>
              <span>
                {info.address.city} {info.address.zip}
              </span>
            </li>
            <li>
              <span>{info.address.country}</span>
            </li>
          </>
        </Column>
        <Column title={t('footer.company.title')}>
          <>
            <li>
              <span>{t('footer.company.ico')}</span>: <span>{info.company.ico}</span>
            </li>
            <li>
              <span>{t('footer.company.dic')}</span>: <span>{info.company.dic}</span>
            </li>
            <li>
              <span>{t('footer.company.icDph')}</span>: <span>{info.company.icDph}</span>
            </li>
          </>
        </Column>
        <Column title={t('footer.contact.title')}>
          <>
            <li>
              <a href={`mailto:${info.contact.email}`} title={t('footer.contact.email')}>
                {info.contact.email}
              </a>
            </li>
            <li>
              <a href={`tel:${info.contact.phone}`} title={t('footer.contact.phone')}>
                {info.contact.phone} (SK)
              </a>
            </li>
            <li>
              <a href={`tel:${info.contact.phoneIt}`} title={t('footer.contact.phone')}>
                {info.contact.phoneIt} (IT)
              </a>
            </li>
          </>
        </Column>
        <Column title={t('footer.pages.title')}>
          <>
            {pages.map((page) => (
              <li key={page}>
                <AnchorLink className="" href={`#${page}`}>
                  <span>{t(`sections.${page}.menuTitle`)}</span>
                </AnchorLink>
              </li>
            ))}
            <li>
              <a
                onClick={() => setModalOpen(true)}
                onKeyDown={() => setModalOpen(true)}
                role="button"
                tabIndex={0}
                title={t('vop.title')}
              >
                {t('vop.title')}
              </a>
            </li>
          </>
        </Column>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={modalStyles}
        contentLabel="Modal"
        closeTimeoutMS={0}
      >
        <>
          <a
            className="absolute top-2 right-2 text-3xl"
            onClick={() => setModalOpen(false)}
            onKeyDown={() => setModalOpen(false)}
            role="button"
            tabIndex={0}
            title="Close"
          >
            <MdClose />
          </a>
          <VopPage />
        </>
      </Modal>
    </footer>
  );
};

export default Footer;
